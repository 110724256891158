import Event from "@/views/sysviews/config/business/event";
import utils from "@/utils/Jh.core";
//该模块处理弹出框包含的卡片或者单据相关数据
export default class EventCardBillHelper {
    //【处理弹出框打开的pageInfo】后台查询完成之后会调用beforeOpen方法，然后会调下面这个方法，主要是对后台的数据作处理来改变前台的显示
    static dealCardBillPageInfo(params:any,detailEvents:any={}):void{
        let modelName=params.eventName.split('_')[0].substr(1);
        let hasDetails:boolean=false,details:Array<string>=[],roles:any={};
        let pageInfo=JSON.parse(params.event.detail.res.pageInfo);
        //必填规则
        if(pageInfo.formInfo && pageInfo.formInfo.roles){
            for(let key of Object.keys(pageInfo.formInfo.roles)){
                pageInfo.formInfo.roles[key] = [utils.UtilPub.commonValidRule(pageInfo.formInfo.roles[key])];
            }
            roles=pageInfo.formInfo.roles;
        }

        //表单项
        if(pageInfo.formItems){
            //必须通过以下方式重新赋值，才能改变form中的值
            params.event.detail.engineInst.otherParams.compParams.formItems=pageInfo.formItems;
            //处理卡片表单中的组件
            Event.bindCompPropEvent(params.event.detail.engineInst.otherParams.compParams.formItems,modelName,'form');
        }
        //表单明细项
        if(pageInfo.detailInfos){
            hasDetails=true;
            let detailTable=params.event.detail.engineInst.otherParams.compParams.detailInfos;
            for(let key of Object.keys(detailTable)){
                //处理卡片明细表格中的组件
                Event.bindCompPropEvent(detailTable[key].columns,modelName,key);
                details.push(key);
            }
        }
        params.event.detail.engineInst.utilInst.dialogInst.options.fullscreen=pageInfo.formInfo.fullscreen;//弹出框是否全屏
        //给引擎的compParams重新赋值
        Object.assign(params.event.detail.engineInst.otherParams.compParams,
            {
                colNum:pageInfo.formInfo.colNum,
                rules:roles,
                hasDetails:hasDetails,
                details:details,
                events:detailEvents
            })
        // switch (modelName) {//可以根据不同的模块额外处理
        //     case 'complexCard':
        //         break;
        // }
    }
}